<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/services')"
          >
            <statistic-card
              :statistic="dashboard.counters.services"
              icon="SettingsIcon"
              statistic-title="Servis Kayıtları"
              color="primary"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/machines')"
          >
            <statistic-card
              :statistic="dashboard.counters.machines"
              icon="CodesandboxIcon"
              statistic-title="Makina Kartları"
              color="success"
            />
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BRow, BOverlay,
} from 'bootstrap-vue'
import StatisticCard from '@/views/Dashboard/components/StatisticCard.vue'

export default {
  name: 'Users',
  components: {
    BOverlay,
    BRow,
    BCol,
    StatisticCard,
  },
  data() {
    return {
      variant: 'transparent',
      opacity: 0.90,
      blur: '2px',
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dashboard() {
      return this.$store.getters['dashboard/dashboard']
    },
    loading() {
      return this.$store.getters['dashboard/loading']
    },
    filters() {
      return this.$store.getters['dashboard/filters']
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        if (val.sdate || val.edate) {
          this.getDashboard()
        }
      },
    },
  },
  created() {
    this.getDashboard()
  },
  methods: {
    getDashboard() {
      this.$store.dispatch('dashboard/getData', this.filters)
    },
  },
}
</script>
