<template>
  <div>
    <component
      :is="component"
      v-if="component"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Manager from '@/views/Dashboard/Manager.vue'
import Users from '@/views/Dashboard/Users.vue'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
    BCardText,
    Manager,
    Users,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      component: null,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    this.dashboardType()
  },
  methods: {
    dashboardType() {
      if (this.userData.role === 'client') {
        this.component = 'Users'
      }
      if (this.userData.role === 'admin') {
        this.component = 'Manager'
      }
    },
  },
}
</script>

<style>

</style>
